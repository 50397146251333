import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, Button, Table, TextField, InputAdornment, TableContainer, TableHead, TableRow, TableCell, TableBody, Tooltip, IconButton, Dialog, DialogContent, DialogActions, DialogContentText, DialogTitle, CircularProgress, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RightDrawer from '../../../components/right-drawer/RightDrawer';
import { toggleLoading, toggleDrawer } from "../../../redux/actions/app";
import Transition from '../../../components/transition/Transition';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';
import ReportIcon from '@mui/icons-material/Report';
import EditIcon from '@mui/icons-material/Edit';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import BurstModeIcon from '@mui/icons-material/BurstMode';
import Pagination from '@mui/material/Pagination';
import toast from 'react-hot-toast';

import { getMyClients, disableClient, activeClient } from '../../../redux/actions/business';
import BusinessAddClient from './AddClient';
import BusinessEditClient from './EditClient';
import GenerateQrCode from './GenerateQrCode';
import GalleryClient from './GalleryClient';
import CategoryIcon from '@mui/icons-material/Category';

import styles from "./clients.module.scss";

let debounce = null;

const BusinessClients = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const clients = useSelector((state) => state.business.clients);
    const isDrawerOpen = useSelector((state) => state.app.isRightDrawerOpen);

    const [form, setForm] = useState();
    const [selectedClient, setSelectedClient] = useState();
    const [deleteClientDialog, setDeleteClientDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState();
    const [params, setParams] = useState({ page: 1, deleted: '0', search: '' })

    const [qrCodeText, setQrCodeText] = useState();

    const fetchData = () => {
        setLoading(true);
        dispatch(getMyClients(params)).then(res => {
            setLoading(false);
        });
    }

    useEffect(() => {
        fetchData();
    }, [params])

    const openNewClientForm = () => {
        setForm("add");
        dispatch(toggleDrawer(true));
    }

    const openEditClientForm = (client) => {
        setSelectedClient(client);
        setForm("edit");
        dispatch(toggleDrawer(true));
    }

    const openDeleteDialog = (client) => {
        setSelectedClient(client);
        setDeleteClientDialog(true);
    }

    const onDeleteHandler = () => {
        dispatch(disableClient(selectedClient.id)).then(res => {
            fetchData();
            setSelectedClient();
            setDeleteClientDialog(false);
            toast.success("Klienti u anulua me sukses!")
        }).catch(e => {
            setSelectedClient();
            setDeleteClientDialog(false);
            toast.success("Dicka shkoi keq, ju lutem provoni perseri!")
        })
    }

    const generateQrCode = (client) => {
        setQrCodeText(`http://scan-us.com/client/${client.slug}`);
        setSelectedClient(client);
        setForm('qrcode');
        dispatch(toggleDrawer(true));
    }

    const openGalleryClientForm = (client) => {
        setSelectedClient(client);
        setForm("gallery");
        dispatch(toggleDrawer(true));
    }

    const handleChangePagination = (event, value) => {
        setParams({ ...params, page: value });
    };

    const searchByName = (e) => {
        setSearch(e.target.value)
        clearTimeout(debounce);
        debounce = setTimeout(() => {
            setParams({ ...params, search: e.target.value })
        }, 500);
    };

    const clearSearch = () => {
        setSearch("");
        setParams({ ...params, search: '' });
    }

    const activateClientHandler = (client) => {
        dispatch(activeClient(client.id)).then(res => {
            fetchData();
            toast.success("Klienti u aktivizua me sukses!")
        }).catch(e => {
            toast.success("Dicka shkoi keq, ju lutem provoni perseri!")
        })
    }

    const goToCategoryGallery = (client) => {
        navigate(`/business/clients/category-gallery/${client.id}`);
    }

    return (
        <Card>
            <CardContent>
                <div className="d-flex justify-content-between">
                    <div className="pt-2">
                        <p className={styles.title}>Lista e klienteve: <span>{clients && clients.total}</span></p>
                    </div>
                    <div>
                        <Button 
                            variant="contained" 
                            onClick={openNewClientForm}
                        >
                            <AddCircleOutlineIcon fontSize="small" />
                        </Button>
                    </div>
                </div>
                <hr></hr>
                <div className='row'>
                    <div className="col-md-3">
                        <TextField 
                            id="outlined-basic" 
                            size="small" 
                            variant="outlined" 
                            onChange={searchByName} 
                            className="my-2" 
                            value={search} 
                            label="Kerko" 
                            fullWidth={true} 
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment>
                                        {search ? <CancelIcon className="on-hover" onClick={() => clearSearch()} /> : <SearchIcon />}
                                    </InputAdornment>
                                )
                            }}
                        />
                    </div>
                    <div className='col-md-2'>
                        <FormControl variant="outlined" fullWidth={true} className="" size="small">
                            <InputLabel className="mt-2" id="demo-simple-select-outlined-label">Statusi</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={params.deleted}
                                onChange={(e) => setParams({ ...params, deleted: e.target.value})}
                                label="Statusi"
                                name="status"
                                className="mt-2"
                            >
                                <MenuItem value="0">
                                    Aktive
                                </MenuItem>
                                <MenuItem value="1">
                                    Anuluar
                                </MenuItem>                             
                            </Select>
                        </FormControl>
                    </div>
                </div>
                {loading ? (
                    <div className='text-center mt-4'>
                        <CircularProgress size="18px" />
                    </div>
                ) : (
                    <div>
                        <TableContainer >
                            <Table sx={{ minWidth: 650 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell><b>Klienti</b></TableCell>
                                        <TableCell><b>Kontakti</b></TableCell>
                                        <TableCell><b>Dita e dasmes</b></TableCell>
                                        <TableCell><b>Status</b></TableCell>
                                        <TableCell><b></b></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {clients && clients.data && clients.data.map((client, index) => (
                                        <TableRow className={form !== "add" && selectedClient && selectedClient.id === client.id && isDrawerOpen && styles.selectedRow}>
                                            <TableCell>{client.fullname}</TableCell>
                                            <TableCell>{client.contact}</TableCell>
                                            <TableCell>{client.deadline}</TableCell>
                                            <TableCell>
                                                    {client.type === 'before_wedding' ? 'Para dasme' : 'Pas dasme'}
                                            </TableCell>
                                            <TableCell align="center">
                                                <Tooltip title="Gjenero QR Code" placement="top">
                                                    <IconButton onClick={() => generateQrCode(client)}>
                                                        <QrCode2Icon fontSize="small" color="primary" />
                                                    </IconButton>
                                                </Tooltip>
                                                {client.type === 'before_wedding' ? (
                                                    <Tooltip title="Galeria" placement="top">
                                                        <IconButton onClick={() => openGalleryClientForm(client)}>
                                                            <BurstModeIcon fontSize="small" color="inherit" />
                                                        </IconButton>
                                                    </Tooltip>
                                                ) : (
                                                    <Tooltip title="Kategoritë dhe galeria" placement="top">
                                                        <IconButton onClick={() => goToCategoryGallery(client)}>
                                                            <CategoryIcon fontSize="small" color="inherit" />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                                <Tooltip title="Ndrysho klientin" placement="top">
                                                    <IconButton onClick={() => openEditClientForm(client)}>
                                                        <EditIcon fontSize="small" color="inherit" />
                                                    </IconButton>
                                                </Tooltip>
                                                {client.disabled === "0" || client.disabled === 0 ? (
                                                    <Tooltip title="Anulo klientin" placement="top">
                                                        <IconButton onClick={() => openDeleteDialog(client)}>
                                                            <DoNotDisturbOnIcon fontSize="small" color="inherit" />
                                                        </IconButton>
                                                    </Tooltip>
                                                ) : (
                                                    <Tooltip title="Aktivizo klientin" placement="top">
                                                        <IconButton onClick={() => activateClientHandler(client)}>
                                                            <SettingsBackupRestoreIcon fontSize="small" color="inherit" />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {clients && clients.total === 0 && (
                            <p className='mt-4 ms-3' style={{fontSize: '15px'}}>Nuk u gjet asnje klient</p>
                        )}
                        {clients.total > 0 && (
                            <Pagination className='mt-4' count={clients.last_page} page={params.page} onChange={handleChangePagination} />
                        )}
                    </div>
                )}
                
            </CardContent>

            <RightDrawer
                title={form === "add" ? "Shto klient" : form === "edit" ? "Ndrysho klientin" : form === "gallery" ? "Galeria" : "Gjenero Qr Code"}
                width={300}
            >
                { form === 'add' && <BusinessAddClient onFinish={fetchData} /> }
                { form === 'edit' && <BusinessEditClient client={selectedClient} onFinish={fetchData} /> }
                { form === 'qrcode' && <GenerateQrCode qrCodeText={qrCodeText} client={selectedClient} /> }
                { form === 'gallery' && <GalleryClient client={selectedClient} /> }
            </RightDrawer>

            {/* DIALOG DELETE */}
            <Dialog
                open={deleteClientDialog}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setDeleteClientDialog(false)}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle align="center"><ReportIcon /></DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            A jeni i sigurtë që doni të anuloni klientin?
                        </DialogContentText>
                    </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteClientDialog(false)}>Jo</Button>
                    <Button onClick={onDeleteHandler}>Po</Button>
                </DialogActions>
            </Dialog>
        </Card>
    );
};

export default BusinessClients;