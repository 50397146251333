import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AdminRoute from './routes/AdminRoute';
import BusinessRoute from './routes/BusinessRoute';

import Home from './pages/home/Home';
import Login from './pages/login/Login';

// Public
import Client from './pages/client/Client';
import GogajClient from './pages/client/GogajClient';

// Admin
import AdminHome from './pages/admin/home/AdminHome';

// Business
import BusinessHome from './pages/business/home/BusinessHome';
import Clients from './pages/business/clients/Clients';
import CategoryGalleryClient from './pages/business/clients/CategoryGalleryClient';
import BusinessProfile from './pages/business/profile/Profile';

// Gogaj
import GogajHome from './pages/gogaj/GogajHome';
import Products from './pages/gogaj/Products';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" exact element={<Login />} />
        <Route path="/login" element={<Login />} />

        {/* Public Routes */}
        <Route path="/client/:slug" element={<Client />} />

        {/* Admin Routes  */}
        <Route path="/admin" exact element={<AdminRoute><AdminHome /></AdminRoute>} />

        {/* Business Routes */}
        <Route path="/business" exact element={<BusinessRoute><BusinessHome /></BusinessRoute>} />
        <Route path="/business/clients" exact element={<BusinessRoute><Clients /></BusinessRoute>} />
        <Route path="/business/clients/category-gallery/:id" exact element={<BusinessRoute><CategoryGalleryClient /></BusinessRoute>} />
        <Route path="/business/profile" exact element={<BusinessRoute><BusinessProfile /></BusinessRoute>} />

        {/* Gogaj Routes */}
        <Route path="/gogaj" exact element={<BusinessRoute><GogajHome /></BusinessRoute>} />
        <Route path="/gogaj/products" exact element={<BusinessRoute><Products /></BusinessRoute>} />
        <Route path="/gogajclient/:slug" element={<GogajClient />} />

      </Routes>
    </Router>
  );
}

export default App;
