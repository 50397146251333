import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { TextField, Button, LinearProgress } from "@mui/material";
import { toggleDrawer, toggleLoading } from '../../redux/actions/app';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import Stack from '@mui/material/Stack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import toast from 'react-hot-toast';

import { updateClient, clientBeforeWedding, clientAfterWedding } from '../../redux/actions/business';

import styles from './clients.module.scss';

const BusinessEditClient = (props) => {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [fullname, setFullname] = useState(props.client.fullname);
    const [contact, setContact] = useState(props.client.contact);
    const [deadline, setDeadline] = useState(props.client.deadline);
    const [deadlineTransfrom, setDeadlineTransfrom] = useState();
    const [weddingLocation, setWeddingLocation] = useState(props.client.wedding_location);

    const getMonth = (month) => {
        const m = month + 1;
        if(m < 10){
            return "0" + m;
        }else{
            return m;
        }
    };

    const selectDeadlineDayHandler = (newValue) => {
        setDeadline(newValue);
        const date = newValue.getFullYear() + "-" + getMonth(newValue.getMonth()) + "-" + newValue.getDate();
        setDeadlineTransfrom(date);
    }

    const updateClientSubmitForm = (e) => {
        e.preventDefault();
        setLoading(true)

        const client_data = {
            "fullname": fullname,
            "contact": "1",
        };

        dispatch(updateClient(client_data, props.client.id)).then(res => {
            setLoading(false);
            dispatch(toggleDrawer(false));
            toast.success('Klienti u ndryshua me sukses');
            props.onFinish();
        }).catch(error => {
            setLoading(false)
            toast.error('Dicka shkoi keq, ju lutem provoni perseri!');
            dispatch(toggleDrawer(false));
        })
    }

    const changeToAfterWedding = () => {
        setLoading(true)

        dispatch(clientAfterWedding(props.client.id)).then(res => {
            setLoading(false)
            dispatch(toggleDrawer(false));
            toast.success('Statusi i klientit u ndryshua me sukses');
            props.onFinish();
        }).catch(e => {
            setLoading(false)
            toast.error('Dicka shkoi keq, ju lutem provoni perseri!');
            dispatch(toggleDrawer(false));
        })
    }

    const changeToBeforeWedding = () => {
        setLoading(true)

        dispatch(clientBeforeWedding(props.client.id)).then(res => {
            setLoading(false)
            dispatch(toggleDrawer(false));
            toast.success('Statusi i klientit u ndryshua me sukses');
            props.onFinish();
        }).catch(e => {
            setLoading(false)
            toast.error('Dicka shkoi keq, ju lutem provoni perseri!');
            dispatch(toggleDrawer(false));
        })
    }

    return (
        <div>
            <form autoComplete="off" onSubmit={updateClientSubmitForm} encType="multipart/form-data">
                <div className='mt-2'>
                    <TextField
                        name="fullname"
                        variant="outlined"
                        fullWidth
                        label="Produkti"
                        size="small"
                        value={fullname}
                        onChange={(e) => { setFullname(e.target.value) }}
                        required
                    />
                </div>
                {/* <div className='mt-2'>
                    <TextField
                        name="contact"
                        variant="outlined"
                        fullWidth
                        label="Numri i telefonit"
                        size="small"
                        value={contact}
                        onChange={(e) => { setContact(e.target.value) }}
                        required
                    />
                </div>
                <div className="mt-2">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Stack spacing={3}>
                            <DesktopDatePicker
                                label="Dita e dasmes"
                                inputFormat="MM/dd/yyyy"
                                value={deadline ? deadline : null}
                                onChange={selectDeadlineDayHandler}
                                renderInput={(params) => <TextField {...params} variant="outlined" size="small" />}
                            />
                        </Stack>
                    </LocalizationProvider>
                </div>
                <div className='mt-2'>
                    <TextField
                        name="weddingLocation"
                        variant="outlined"
                        fullWidth
                        label="Lokacioni dasmes"
                        size="small"
                        value={weddingLocation}
                        onChange={(e) => { setWeddingLocation(e.target.value) }}
                    />
                </div> */}

                <div className="text-center mt-4">
                    <Button variant="contained" type="submit">
                        Ndrysho produktin
                    </Button>
                    {loading && <LinearProgress color="primary" className="mt-1" />}               
                </div>
            </form>
        </div>
    );
};

export default BusinessEditClient;