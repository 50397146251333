import React from 'react';
import QRCode from 'qrcode.react';
import { Button } from '@mui/material';

const GenerateQrCode = (props) => {

    const downloadQrCode = () => {
        const qrCodeURL = document.getElementById('qrCodeEl')
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        let aEl = document.createElement("a");
        aEl.href = qrCodeURL;
        aEl.download = `qr-code-${props.client.fullname}.png`;
        document.body.appendChild(aEl);
        aEl.click();
        document.body.removeChild(aEl);
    }

    return (
        <div>
            <div className='text-center'>
                <QRCode
                    id="qrCodeEl"
                    size={150}
                    value={props.qrCodeText}
                    bgColor={'transparent'}
                />
            </div>
            <div className='text-center'>
                <Button variant="contained" className='mt-3' onClick={() => downloadQrCode()}>
                    Shkarko
                </Button>
            </div>
        </div>
    );
};

export default GenerateQrCode;