import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { TextField, Button, LinearProgress, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { toggleDrawer, toggleLoading } from '../../../../redux/actions/app';
import { storeImageOfClientWithCategory } from '../../../../redux/actions/business';
import toast from 'react-hot-toast';

import styles from '../clients.module.scss';

const AddImage = (props) => {
    const dispatch = useDispatch();
    const client = useSelector((state) => state.business.client);

    const [loading, setLoading] = useState(false);
    const [image, setImage] = useState();
    const [categoryId, setCategoryId] = useState();

    const newImageHandler = (e) => {
        e.preventDefault();
        setLoading(true)

        const formData = new FormData()
        formData.append('client_id', props.clientId);
        formData.append('image', image);
        formData.append('category_id', categoryId);

        dispatch(storeImageOfClientWithCategory(formData)).then(res => {
            toast.success("Foto u shtua me sukses!")
            setLoading(false);
        }).catch(e => {
            toast.error("Dicka shkoi keq, ju lutem provoni perseri!")
        })
    }

    const handleChangeCategory = (event) => {
        setCategoryId(event.target.value);
    };

    const uploadImage = (e) => {
        setImage(e.target.files[0]);
    }

    return (
        <form autoComplete="off" onSubmit={newImageHandler}>
            <div className='mt-2'>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Kategoria</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={categoryId}
                        label="Kategoria"
                        required
                        onChange={handleChangeCategory}
                    >
                        {client && client.categories.map(category => (
                            <MenuItem value={category.id}>{category.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <div className='mt-2'>
                <input
                    type="file"
                    required
                    onChange={uploadImage}
                />
            </div>  
            <div className="text-center mt-4">
                <Button variant="contained" type="submit">
                    Shto foto
                </Button>
                {loading && <LinearProgress color="primary" className="mt-1" />}               
            </div>
        </form>
    );
};

export default AddImage;