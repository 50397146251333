import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { Container, Button, Menu, MenuItem} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logoutUser } from '../../redux/actions/auth';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import LogoutIcon from '@mui/icons-material/Logout';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

import './agency-dashboard.scss';

const Dashboard = ({children}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state) => state.auth.user);

    const [open, setOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState()

    const handleClickMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl("");
    };

    const toggle = () => {
        setOpen(!open)
    }

    const logOutHandler = () => {
        dispatch(logoutUser());
        navigate("/");
    }
    
    return(
        <div className="agency-dashboard">
            <div className={open ? "agency-sidebar-active" : "agency-sidebar"}>

                <div className="logo_content">
                    <div className="logo">
                        LOGO
                    </div>
                    <div onClick={toggle} className="toggle-icon">
                        {open ? <ArrowBackIcon /> : <MenuIcon />}
                    </div>
                </div>

                {/* <div className="divider"></div> */}

                {user && user.role === 'admin' && (
                    <ul className="nav_list">
                        <li>
                            <Link to="/admin">
                                <div className="icon"><HomeIcon /></div>
                                <span className="link_name">Dashboard</span>
                            </Link>
                            <p className="tooltip">Dashboard</p>
                        </li>
                    </ul>
                )}

                {user && user.role === 'business' && (
                    <ul className="nav_list">
                        <li>
                            <Link to="/business">
                                <div className="icon"><HomeIcon /></div>
                                <span className="link_name">Dashboard</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/business/clients">
                                <div className="icon"><GroupAddIcon /></div>
                                <span className="link_name">Klientet</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/business/profile">
                                <div className="icon"><ManageAccountsIcon /></div>
                                <span className="link_name">Profili im</span>
                            </Link>
                        </li>
                        <div className="divider">
                            <div className="logout-button" style={{marginBottom: '70px'}}>
                                <LogoutIcon onClick={logOutHandler} />
                            </div>
                        </div>
                    </ul>
                )}

                {user && user.role === 'gogaj' && (
                    <ul className="nav_list">
                        <li>
                            <Link to="/gogaj">
                                <div className="icon"><HomeIcon /></div>
                                <span className="link_name">Dashboard</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/gogaj/products">
                                <div className="icon"><GroupAddIcon /></div>
                                <span className="link_name">Produktet</span>
                            </Link>
                        </li>
                        {/* <li>
                            <Link to="/business/profile">
                                <div className="icon"><ManageAccountsIcon /></div>
                                <span className="link_name">Profili im</span>
                            </Link>
                        </li> */}
                        <div className="divider">
                            <div className="logout-button" style={{marginBottom: '70px'}}>
                                <LogoutIcon onClick={logOutHandler} />
                            </div>
                        </div>
                    </ul>
                )}

                
            </div>
            <div className={open ? "agency-container-active" : "agency-container"}>
                <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                    {children}
                </Container>
            </div>
        </div>
    )
}

export default Dashboard;