import * as c from "../constants";
import API from '../api';

// Business Profile
export const changeProfile = (data) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}` 
            }
        }
        const response = await API.post('/changeProfile', data, config)
        return response
    } catch (error) {
        throw error
    }
}

export const changeProfileImage = (data) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}` 
            }
        }
        const response = await API.post('/changeProfileImage', data, config)
        return response
    } catch (error) {
        throw error
    }
}

// Business Clients
export const getMyClients = (params) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}` 
            }
        }
        const response = await API.get(`/getMyClients?page=${params.page}&deleted=${params.deleted}${params.search !== '' ? `&search=${params.search}` : ``}`, config)
        dispatch({ type: c.GET_MY_CLIENTS, payload: response.data })
        return response
    } catch (error) {
        throw error
    }
}

export const getGogajProducts = (params) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}` 
            }
        }
        const response = await API.get(`/getGogajProducts?page=${params.page}&deleted=${params.deleted}${params.search !== '' ? `&search=${params.search}` : ``}`, config)
        dispatch({ type: c.GET_MY_CLIENTS, payload: response.data })
        return response
    } catch (error) {
        throw error
    }
}

export const getClient = (id) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}` 
            }
        }
        const response = await API.get(`/getClient/${id}`, config)
        dispatch({ type: c.GET_MY_CLIENT, payload: response.data })
        return response
    } catch (error) {
        throw error
    }
}

export const storeClient = (data) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}` 
            }
        }
        const response = await API.post('/storeClient', data ,config)
        return response
    } catch (error) {
        throw error
    }
}

export const storeImageOfClient = (data) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}` 
            }
        }
        const response = await API.post('/storeImageOfClient', data, config)
        return response
    } catch (error) {
        throw error
    }
}

export const storeImageOfClientWithCategory = (data) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}` 
            }
        }
        const response = await API.post('/storeImageOfClientWithCategory', data, config)
        return response
    } catch (error) {
        throw error
    }
}

export const deleteImageOfClient = (id) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}` 
            }
        }
        const response = await API.delete(`/deleteImageOfClient/${id}`, config)
        return response
    } catch (error) {
        throw error
    }
}

export const updateClient = (data, id) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}` 
            }
        }
        const response = await API.post(`/updateClient/${id}`, data, config)
        return response
    } catch (error) {
        throw error
    }
}

export const disableClient = (id) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}` 
            }
        }
        const response = await API.delete(`/disableClient/${id}`, config)
        return response
    } catch (error) {
        throw error
    }
}

export const activeClient = (id) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}` 
            }
        }
        const response = await API.get(`/activeClient/${id}`, config)
        return response
    } catch (error) {
        throw error
    }
}

export const clientAfterWedding = (id) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}` 
            }
        }
        const response = await API.get(`/clientAfterWedding/${id}`, config)
        return response
    } catch (error) {
        throw error
    }
}

export const clientBeforeWedding = (id) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}` 
            }
        }
        const response = await API.get(`/clientBeforeWedding/${id}`, config)
        return response
    } catch (error) {
        throw error
    }
}

export const storeCategory = (data) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}` 
            }
        }
        const response = await API.post('/storeCategory', data ,config)
        return response
    } catch (error) {
        throw error
    }
}

export const updateCategory = (data, id) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}` 
            }
        }
        const response = await API.post(`/updateCategory/${id}`, data, config)
        return response
    } catch (error) {
        throw error
    }
}

export const deleteCategory = (id) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}` 
            }
        }
        const response = await API.delete(`/deleteCategory/${id}`, config)
        return response
    } catch (error) {
        throw error
    }
}

export const storeVideoOfClient = (data) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}` 
            }
        }
        const response = await API.post('/storeVideoOfClient', data, config)
        return response
    } catch (error) {
        throw error
    }
}

export const deleteVideoOfClient = (id) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}` 
            }
        }
        const response = await API.delete(`/deleteVideoOfClient/${id}`, config)
        return response
    } catch (error) {
        throw error
    }
}