import React, { useEffect, useState } from 'react';
import { Tooltip, IconButton } from '@mui/material';
import { Button } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { toggleLoading, toggleDrawer } from "../../redux/actions/app";
import { getClient, deleteImageOfClient, storeImageOfClient } from '../../redux/actions/business';
import DeleteIcon from '@mui/icons-material/Delete';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import toast from 'react-hot-toast';

import styles from "./clients.module.scss";

const GalleryClient = (props) => {
    const dispatch = useDispatch();
    const client = useSelector((state) => state.business.client);
    
    const [loading, setLoading] = useState(false);

    const fetchData = () => {
        setLoading(true);
        dispatch(toggleLoading(true));
        dispatch(getClient(props.client.id)).then(res => {
            dispatch(toggleLoading(false));
            setLoading(false);
        }).catch(e => {
            dispatch(toggleDrawer(false));
            dispatch(toggleLoading(false));
            toast.error("Dicka shkoi keq, ju lutem provoni perseri");
        })
    };

    useEffect(() => {
        fetchData();
    }, []);

    const deleteImage = (img) => {
        dispatch(toggleLoading(true));
        dispatch(deleteImageOfClient(img.id)).then(res => {
            dispatch(toggleLoading(false));
            fetchData();
            toast.success("Foto u fshi me sukses");
        }).catch(e => {
            dispatch(toggleLoading(false));
            toast.error("Dicka shkoi keq, ju lutem provoni perseri");
        })
    }

    const uploadImage = (e) => {
        dispatch(toggleLoading(true));
        const formData = new FormData()
        formData.append('client_id', props.client.id);
        formData.append('image', e.target.files[0]);
        dispatch(storeImageOfClient(formData)).then(res => {
            toast.success("Foto u shtua me sukses!")
            dispatch(toggleLoading(false));
            fetchData();
        }).catch(error => {
            dispatch(toggleLoading(false));
            toast.error("Dicka shkoi keq, ju lutem provoni perseri!")
        });
    }

    return (
        <div>
            <div className='d-flex justify-content-end'>
                <Button 
                    variant="contained" 
                    component="label"
                    fontSize="small"
                >
                    Shto foto <AddPhotoAlternateIcon className='ms-1' fontSize="small" />
                    <input
                        type="file"
                        hidden
                        onChange={uploadImage}
                    />
                </Button>
            </div>
            {!loading && (
                <div>
                    {client.images && client.images.map(img => (
                        <div>
                            <hr></hr>
                            <div className='d-flex justify-content-around'>
                                <div className='text-center'>
                                    <img src={img.imageUrl} width="50%" />
                                </div>  
                                <div>
                                    <Tooltip title="Fshije foton" placement="top">
                                        <IconButton onClick={() => deleteImage(img)}>
                                            <DeleteIcon fontSize="small" color="inherit" />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </div>
                            <hr></hr>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default GalleryClient;