export const TOGGLE_DRAWER = "TOGGLE_DRAWER";
export const TOGGLE_LOADING_FORM = "TOGGLE_LOADING_FORM";

export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const GET_USER = "GET_USER";

export const GET_CLIENT = "GET_CLIENT";
export const GET_CLIENT_BEFORE_WEDDING = "GET_CLIENT_BEFORE_WEDDING";
export const GET_CLIENT_AFTER_WEDDING = "GET_CLIENT_AFTER_WEDDING";

export const GET_MY_CLIENTS = "GET_MY_CLIENTS";
export const GET_MY_CLIENT = "GET_MY_CLIENT";