import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Box from '@mui/material/Box';
import { getClientBySlug, getImagesBeforeWeeding, getImagesAfterWedding } from '../../redux/actions/public';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import locationImg from "./location.png";
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import styles from "./client.module.scss";
import './footer.css';
import { CardContent, Card } from '@mui/material';

const GogajClient = () => {
    const dispatch = useDispatch();
    const { slug } = useParams();
    const client = useSelector((state) => state.public.client);
    const clientImagesBeforeWedding = useSelector((state) => state.public.images_before_wedding);
    const clientImagesAfterWedding = useSelector((state) => state.public.images_after_wedding);

    const [loading, setLoading] = useState(true);
    const [value, setValue] = useState("all");
    const [selectedImage, setSelectedImage] = useState();
    const [filteredImages, setFilteredImages] = useState();

    const handleChange = (event, newValue) => {
        setValue(newValue);
        if(newValue !== "all" && newValue !== "video"){
            setFilteredImages(clientImagesAfterWedding.filter(img => img.category_id.toString() === newValue.toString()));
        }
    };

    useEffect(() => {
        setLoading(true);
        dispatch(getClientBySlug(slug)).then(res => {
            if(res.data.type === 'before_wedding'){
                dispatch(getImagesBeforeWeeding(slug)).then(res => {
                    setLoading(false);
                }).catch(e => {
                    setLoading(false);
                })
            }else{
                dispatch(getImagesAfterWedding(slug)).then(res => {
                    setLoading(false);
                }).catch(e => {
                    setLoading(false);
                })
            }
        }).catch(e => {
            setLoading(false);
        });
    }, []);

    return(
        <div className={styles.ClientWrapper}>
            {!loading && (
                <div>
                    <Carousel swipeable="true" autoPlay={true} interval={5000}>
                        {client && clientImagesBeforeWedding && clientImagesBeforeWedding.map((img, index) => (
                            <div key={index} className="d-flex align-items-center">
                                <img src={img.imageUrl} alt="" className="imgInCarousel" />
                            </div>
                        ))}                                
                    </Carousel>
                    <hr></hr>
                    <div className='text-center footer'>
                        {client.business.image && (
                            <div className='d-flex justify-content-center'>
                                <div className="imageProfileOfBusiness" style={{backgroundImage: `url(${client.business.imageUrl})`}}>
                                </div>
                            </div>
                        )}
                        <div>
                            <p className='business-name'>{client.business.name}</p>
                        </div>
                        <div className='text-center'>
                            { client.business.instagram && ( <a className='social-button' target="_blank" href={client.business.instagram}><InstagramIcon color="inherit" /></a> )}
                            { client.business.facebook && (<a className='social-button' target="_blank" href={client.business.facebook}><FacebookIcon /></a> )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default GogajClient;