import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import { Card, CardContent, Tabs, Tab, Box, Button, Table, TextField, InputAdornment, TableContainer, TableHead, TableRow, TableCell, TableBody, Tooltip, IconButton, Dialog, DialogContent, DialogActions, DialogContentText, DialogTitle, CircularProgress, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RightDrawer from '../../../components/right-drawer/RightDrawer';
import { toggleLoading, toggleDrawer } from "../../../redux/actions/app";
import { getClient, deleteCategory, deleteImageOfClient } from '../../../redux/actions/business';
import Transition from '../../../components/transition/Transition';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import toast from 'react-hot-toast';
import ReportIcon from '@mui/icons-material/Report';
import AddCategory from './categories/AddCategory';
import EditCategory from './categories/EditCategory';
import AddImage from './categories/AddImage';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import { storeVideoOfClient, deleteVideoOfClient } from '../../../redux/actions/business';

import styles from "./clients.module.scss";

const CategoryGalleryClient = () => {
    const dispatch = useDispatch();
    const client = useSelector((state) => state.business.client);
    const { id } = useParams();

    const [value, setValue] = React.useState(0);
    const [form, setForm] = useState();
    const [selectedCategory, setSelectedCategory] = useState();
    const [deleteCategoryDialog, setDeleteCategoryDialog] = useState(false);
    const [categoryId, setCategoryId] = useState("all");
    const [imgByCategories, setImgByCategories] = useState();
    const [loading, setLoading] = useState(false);

    const [selectedImage, setSelectedImage] = useState();

    const fetchData = () => {
        dispatch(getClient(id));
    }

    useEffect(() => {
        fetchData();
    }, [])

    const openNewCategoryForm = () => {
        setForm("add");
        dispatch(toggleDrawer(true));
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const openEditCategoryForm = (category) => {
        setForm("edit");
        dispatch(toggleDrawer(true));
        setSelectedCategory(category);
    }

    const openDeleteDialog = (category) => {
        setSelectedCategory(category);
        setDeleteCategoryDialog(true);
    }

    const onDeleteHandler = () => {
        dispatch(deleteCategory(selectedCategory.id)).then(res => {
            fetchData();
            setSelectedCategory();
            setDeleteCategoryDialog(false);
            toast.success("Kategoria u fshi me sukses!")
        }).catch(e => {
            setSelectedCategory();
            setDeleteCategoryDialog(false);
            toast.success("Dicka shkoi keq, ju lutem provoni perseri!")
        })
    }

    const openNewImage = () => {
        setForm("add-image");
        dispatch(toggleDrawer(true));
    }
    
    const handleChangeCategory = (event) => {
        setCategoryId(event.target.value);
        setImgByCategories(
            client.images.filter(img => img.category_id !== null && img.category_id !== 'null' && img.category_id.toString() === event.target.value.toString())
        );
    };

    const uploadVideo = (e) => {
        setLoading(true);
        const formData = new FormData()
        formData.append('client_id', id);
        formData.append('video', e.target.files[0]);
        dispatch(storeVideoOfClient(formData)).then(res => {
            setLoading(false);
            toast.success("Video u shtua me sukses!")
            fetchData();
        }).catch(error => {
            setLoading(false);
            toast.error("Dicka shkoi keq, ju lutem provoni perseri!")
        });
    }

    const deleteClientVideo = (id) => {
        setLoading(true);
        dispatch(deleteVideoOfClient(id)).then(res => {
            toast.success("Video u fshi me sukses!")
            setLoading(false);
            fetchData();
        }).catch(error => {
            setLoading(false);
            toast.error("Dicka shkoi keq, ju lutem provoni perseri!")
        });
    }

    const deleteImg = (id) => {
        dispatch(deleteImageOfClient(id)).then(res => {
            toast.success("Foto u fshi me sukses!");
            setCategoryId("all");
            fetchData();
        }).catch(error => {
            toast.error("Dicka shkoi keq, ju lutem provoni perseri!")
        });
    }

    return (
        <div>
            <Box sx={{ width: '100%', bgcolor: '#fff', padding: '2px 5px', borderRadius: '2px' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Galeria" />
                    <Tab label="Kategoritë" />
                    <Tab label="Video" />
                </Tabs>
            </Box>

            <Card className='mt-2'>
                {value === 0 && (
                    <CardContent>
                        <div className="d-flex justify-content-between">
                            <div className="pt-2">
                                <p className={styles.title}>Galeria: <span></span></p>
                            </div>
                            <div>
                                <Button 
                                    variant="contained" 
                                    onClick={openNewImage}
                                >
                                    <AddCircleOutlineIcon fontSize="small" />
                                </Button>
                            </div>
                        </div>
                        <hr></hr>

                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Kategoria</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={categoryId}
                                label="Kategoria"
                                onChange={handleChangeCategory}
                            >
                                <MenuItem value='all'>Të gjitha</MenuItem>
                                {client && client.categories && client.categories.map(category => (
                                    <MenuItem value={category.id}>{category.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <div className="row mt-2" style={{alignItems: 'center'}}>
                            {client && client.images && categoryId === 'all' && client.images.map((img, index) => (
                                <div className='col-4 mt-2' key={index}>
                                    <DeleteIcon onClick={() => deleteImg(img.id)} className={styles.btnDeleteImg} fontSize="small" color="inherit" />
                                    <img src={img.imageUrl} width="100%" onClick={() => setSelectedImage(img.imageUrl)} />
                                </div>
                            ))}

                            {client && client.images && categoryId !== 'all' && imgByCategories.map((img, index) => (
                                <div className='col-4 mt-2' key={index}>
                                    <DeleteIcon onClick={() => deleteImg(img.id)} className={styles.btnDeleteImg} fontSize="small" color="inherit" />
                                    <img src={img.imageUrl} width="100%" onClick={() => setSelectedImage(img.imageUrl)} />
                                </div>
                            ))}

                        </div>

                        {selectedImage && (
                            <div className={styles.ModalImg} onClick={() => setSelectedImage()}>
                                <img className={styles.ModalContent} src={selectedImage} />
                            </div>
                        )}

                    </CardContent>
                )}
                {value === 1 && (
                    <CardContent>
                        
                        <div className="d-flex justify-content-between">
                            <div className="pt-2">
                                <p className={styles.title}>Lista e kategorive: <span></span></p>
                            </div>
                            <div>
                                <Button 
                                    variant="contained" 
                                    onClick={openNewCategoryForm}
                                >
                                    <AddCircleOutlineIcon fontSize="small" />
                                </Button>
                            </div>
                        </div>
                        <hr></hr>

                        <TableContainer >
                            <Table sx={{ minWidth: 650 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell><b>Kategoria</b></TableCell>
                                        <TableCell><b></b></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {client && client.categories && client.categories.map((category, index) => (
                                        <TableRow>
                                            <TableCell>{category.name}</TableCell>
                                            <TableCell align="center">
                                                <Tooltip title="Ndrysho" placement="top">
                                                    <IconButton onClick={() => openEditCategoryForm(category)}>
                                                        <EditIcon fontSize="small" color="inherit" />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Fshije" placement="top">
                                                    <IconButton onClick={() => openDeleteDialog(category)}>
                                                        <DeleteIcon fontSize="small" color="inherit" />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                    </CardContent>
                )}
                {value === 2 && (
                    <CardContent>
                        <div className="d-flex justify-content-between">
                            <div className="pt-2">
                                <p className={styles.title}>Video: <span></span></p>
                            </div>
                            <div>
                                {client && client.videos.length === 0 && !loading && (
                                    <Button 
                                        variant="contained" 
                                        component="label"
                                        fontSize="small"
                                    >
                                        Shto video <VideoCameraFrontIcon className='ms-1' fontSize="small" />
                                        <input
                                            type="file"
                                            hidden
                                            onChange={uploadVideo}
                                        />
                                    </Button>
                                )}
                                {loading && (
                                    <CircularProgress className='mt-4' size="15px" />
                                )}
                            </div>
                        </div>
                        <hr></hr>
                        
                        {client && client.videos.length > 0 && (
                            <div>
                                <div className='d-flex justify-content-end'>
                                    <Button variant="contained" color="inherit" onClick={() => deleteClientVideo(client.videos[0].id)}>
                                        Fshije videon
                                    </Button>
                                </div>
                                <video width="320" height="240" controls>
                                    <source src={client.videos[0].videoUrl} />
                                </video>
                            </div>
                        )}
                    </CardContent>
                )}
            </Card>

            <RightDrawer
                title={form === "add" ? "Shto Kategori" : form === "edit" ? "Ndrysho kategorinë" : "Shto imazh me kategori"}
                width={300}
            >
                { form === 'add' && <AddCategory onFinish={fetchData} clientId={id} /> }
                { form === 'edit' && <EditCategory onFinish={fetchData} category={selectedCategory} clientId={id} />}
                { form === 'add-image' && <AddImage onFinish={fetchData} clientId={id} />}
            </RightDrawer>

            {/* DIALOG DELETE */}
            <Dialog
                open={deleteCategoryDialog}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setDeleteCategoryDialog(false)}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle align="center"><ReportIcon /></DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            A jeni i sigurtë që doni të fshini kategorine? Fshierja e kategorisë rezulton ne fshierjen e gjitha fotove qe i perkasin kesaj kategorie
                        </DialogContentText>
                    </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteCategoryDialog(false)}>Jo</Button>
                    <Button onClick={onDeleteHandler}>Po</Button>
                </DialogActions>
            </Dialog>

        </div>
    );
};

export default CategoryGalleryClient;