import * as c from "../constants";

const initialState = () => {
    return {
        client: [],
        images_before_wedding: [],
        images_after_wedding: []
    }
}

export const publicReducer = (state = initialState(), action) => {
    switch(action.type){
        case c.GET_CLIENT:
            return { ...state, client: action.payload }
        case c.GET_CLIENT_BEFORE_WEDDING:
            return { ...state, images_before_wedding: action.payload }
        case c.GET_CLIENT_AFTER_WEDDING:
            return { ...state, images_after_wedding: action.payload }
        default:
            return state
    }
}
