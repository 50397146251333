import * as c from "../constants";

const initialState = () => {
    return {
        clients: [],
        client: []
    }
}

export const businessReducer = (state = initialState(), action) => {
    switch(action.type){
        case c.GET_MY_CLIENTS:
            return { ...state, clients: action.payload }
        case c.GET_MY_CLIENT:
            return { ...state, client: action.payload }
        default:
            return state
    }
}
