import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { TextField, Button, LinearProgress } from "@mui/material";
import { toggleDrawer, toggleLoading } from '../../../redux/actions/app';
import { storeClient, storeImageOfClient } from '../../../redux/actions/business';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import Stack from '@mui/material/Stack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import toast from 'react-hot-toast';

import styles from './clients.module.scss';

const BusinessAddClient = (props) => {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [fullname, setFullname] = useState();
    const [contact, setContact] = useState();
    const [deadline, setDeadline] = useState();
    const [deadlineTransfrom, setDeadlineTransfrom] = useState();
    const [weddingLocation, setWeddingLocation] = useState();
    const [images, setImages] = useState([{ image: "" }]);

    const handleChangeImage = (i, e) => {
        let newImages = [...images];
        newImages[i][e.target.name] = e.target.files[0];
        setImages(newImages);
    }
        
    const addNewImageHandler = () => {
        setImages([...images, { image: "np" }])
    }
    
    const removeImageHandler = (i) => {
        let newImages = [...images];
        newImages.splice(i, 1);
        setImages(newImages)
    }

    const newClientSubmitForm = (e) => {
        e.preventDefault();
        setLoading(true)

        const client_data = {
            "fullname": fullname,
            "contact": contact,
            "deadline": deadlineTransfrom,
            "wedding_location": weddingLocation
        };

        dispatch(storeClient(client_data)).then(res => {
            if(res.status === 200){
                const imgLength = images.length
                let i = 0;
                images.forEach(element => {
                    const formData = new FormData()
                    formData.append('client_id', res.data.id)
                    formData.append('image', element.image)
                    dispatch(storeImageOfClient(formData)).then(res => {
                        console.log(res)
                        i = i + 1;
                        if(i === imgLength){
                            setLoading(false);
                            dispatch(toggleDrawer(false));
                            toast.success('Klienti u regjistrua me sukses');
                            props.onFinish();
                        }
                    }).catch(error => {
                        toast.error('Dicka shkoi keq, ju lutem provoni perseri!');
                        dispatch(toggleDrawer(false));
                        setLoading(false)
                        props.onFinish();
                    })
                });
            }else{
                setLoading(false)
                toast.error('Dicka shkoi keq, ju lutem provoni perseri!');
                dispatch(toggleDrawer(false));
            }
        }).catch(error => {
            setLoading(false)
            toast.error('Dicka shkoi keq, ju lutem provoni perseri!');
            dispatch(toggleDrawer(false));
        })
    }

    const getMonth = (month) => {
        const m = month + 1;
        if(m < 10){
            return "0" + m;
        }else{
            return m;
        }
    };

    const selectDeadlineDayHandler = (newValue) => {
        setDeadline(newValue);
        const date = newValue.getFullYear() + "-" + getMonth(newValue.getMonth()) + "-" + newValue.getDate();
        setDeadlineTransfrom(date);
    }

    return (
        <form autoComplete="off" onSubmit={newClientSubmitForm} encType="multipart/form-data">
            <div className='mt-2'>
                <TextField
                    name="fullname"
                    variant="outlined"
                    fullWidth
                    label="Emri dhe mbiemri"
                    size="small"
                    value={fullname}
                    onChange={(e) => { setFullname(e.target.value) }}
                    required
                />
            </div>
            <div className='mt-2'>
                <TextField
                    name="contact"
                    variant="outlined"
                    fullWidth
                    label="Numri i telefonit"
                    size="small"
                    value={contact}
                    onChange={(e) => { setContact(e.target.value) }}
                    required
                />
            </div>
            <div className="mt-2">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Stack spacing={3}>
                        <DesktopDatePicker
                            label="Dita e dasmes"
                            inputFormat="MM/dd/yyyy"
                            value={deadline ? deadline : null}
                            onChange={selectDeadlineDayHandler}
                            renderInput={(params) => <TextField {...params} variant="outlined" size="small" />}
                        />
                    </Stack>
                </LocalizationProvider>
            </div>
            <div className='mt-2'>
                <TextField
                    name="weddingLocation"
                    variant="outlined"
                    fullWidth
                    label="Lokacioni dasmes"
                    size="small"
                    value={weddingLocation}
                    onChange={(e) => { setWeddingLocation(e.target.value) }}
                />
            </div>
            <hr></hr>
            {images && images.map((element, index) => (
                <div className="mt-2">
                    <div>
                        <TextField 
                            size="small" 
                            required
                            id="outlined-basic" 
                            type="file" 
                            variant="outlined" 
                            name="image" 
                            fullWidth={true} 
                            onChange={e => handleChangeImage(index, e)} 
                        />
                        <div className='text-center'>
                            <AddCircleIcon fontSize="small" color="success" onClick={() => addNewImageHandler()} className={styles.iconSuccess} />
                            {
                                index ? 
                                <RemoveCircleIcon fontSize="small" onClick={() => removeImageHandler(index)} className={styles.iconDanger} />
                                : null
                            }
                        </div>
                    </div>
                </div>
            ))}

            <div className="text-center mt-4">
                <Button variant="contained" type="submit">
                    Regjistro klientin
                </Button>
                {loading && <LinearProgress color="primary" className="mt-1" />}               
            </div>
        </form>
    );
};

export default BusinessAddClient;