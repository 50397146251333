import React from 'react';
import { useDispatch } from "react-redux";

import styles from './business-home.module.scss';

const BusinessHome = () => {
    const dispatch = useDispatch();
    return (
        <div>
            <p>Dashboard (work in progress)</p>
        </div>
    );
};

export default BusinessHome;