import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import logger from 'redux-logger';

import { appReducer } from './reducers/app';
import { authReducer } from './reducers/auth';
import { businessReducer } from './reducers/business';
import { publicReducer } from './reducers/public';

const mainReducer = combineReducers({
    app: appReducer,
    auth: authReducer,
    business: businessReducer,
    public: publicReducer
});

const tokenFromStorage = localStorage.getItem('token')
    ? JSON.parse(localStorage.getItem('token'))
    : null

const userFromStorage = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : null

const initialState = {
    auth: { token: tokenFromStorage, user: userFromStorage }
}

const middleware = [thunk, logger]

const store = createStore(
    mainReducer, 
    initialState, 
    composeWithDevTools(applyMiddleware(...middleware))
)

export default store; 