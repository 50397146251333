import React from 'react';
import Dashboard from "../components/dashboard/Dashboard";
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const BusinessRoute = ({ children }) => {
    const user = useSelector((state) => state.auth.user);

    return user && (user.role === 'business' || user.role === 'gogaj') ? <Dashboard>{children}</Dashboard> : <Navigate to="/" />
};

export default BusinessRoute;