import * as c from "../constants";
import API from '../api';

// AUTH LOGIN
export const loginUser = (email, password) => async(dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const response = await API.post('/login', {email, password} ,config)
        dispatch({ type: c.LOGIN, payload: response.data})

        localStorage.setItem('user', JSON.stringify(response.data.user))
        localStorage.setItem('token', JSON.stringify(response.data.accessToken))

        return response
    } catch (error) {
        throw error
    }
}

export const logoutUser = () => async(dispatch, getState) => {
    dispatch({ type: c.LOGOUT })
    localStorage.removeItem('user')
    localStorage.removeItem('token')
}

export const showUser = () => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}` 
            }
        }
        const response = await API.get('/showUser', config)

        dispatch({ type: c.GET_USER, payload: response.data})

        return response
    } catch (error) {
        throw error
    }
}