import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardContent, Button, TextField, CircularProgress } from '@mui/material';
import { changeProfile, changeProfileImage } from "../../../redux/actions/business";
import { showUser } from "../../../redux/actions/auth";
import toast from 'react-hot-toast';

import "./profile.css";
import styles from "./profile.module.scss";

const BusinessProfile = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);

    const [loading, setLoading] = useState(false);
    const [loadingImageUpload, setLoadingImageUpload] = useState(false);

    const [name, setName] = useState();
    const [phone, setPhone] = useState();
    const [city, setCity] = useState();
    const [instagram, setInstagram] = useState();
    const [facebook, setFacebook] = useState();

    const getUser = () => {
        setLoading(true)
        dispatch(showUser()).then(res => {
            setLoading(false);
            setName(res.data.name);
            setPhone(res.data.phone);
            setCity(res.data.city);
            setInstagram(res.data.instagram);
            setFacebook(res.data.facebook);
        }).catch(e => {
            setLoading(false);
            toast.error('Dicka shkoi keq, ju lutem provoni perseri!');
        });
    }

    const getUserAfterImageChange = () => {
        dispatch(showUser()).then(res => {
            
        }).catch(e => {
            toast.error('Dicka shkoi keq, ju lutem provoni perseri!');
        });
    }

    useEffect(() => {
        getUser();
    }, []);

    const uploadImage = (e) => {
        setLoadingImageUpload(true)
        const formData = new FormData()
        formData.append('image', e.target.files[0]);
        dispatch(changeProfileImage(formData)).then(res => {
            toast.success('Foto u shtua me sukses!');
            setLoadingImageUpload(false)
            getUserAfterImageChange();
        }).catch(error => {
            setLoadingImageUpload(false)
            toast.error('Dicka shkoi keq, ju lutem provoni perseri!');
        });
    }

    const updateProfileHandler = (e) => {
        console.log("ok")
        e.preventDefault();
        setLoading(true)

        const profile_data = {
            'name': name,
            'phone': phone,
            'city': city,
            'instagram': instagram,
            'facebook': facebook
        }

        dispatch(changeProfile(profile_data)).then(res => {
            getUser();
            toast.success('Te dhenat e profilit u ndryshuan me sukses!');
        }).catch(error => {
            toast.error('Dicka shkoi keq, ju lutem provoni perseri!');
            setLoading(false)
        })
    }

    return(
        <Card>
            <CardContent>
                <div className="d-flex justify-content-between">
                    <div className="pt-2">
                        <p className={styles.title}>Profili im</p>
                    </div>
                    <div>
                    </div>
                </div>
                <hr></hr>

                {user && (
                    <div className="row">
                        <div className="col-md-4">
                            <div className="d-flex justify-content-center text-center mb-2">
                                <div>
                                    {loadingImageUpload ? (
                                        <div className="imageProfile d-flex justify-content-center align-items-center">
                                            <CircularProgress color="primary" fontSize="small" className="mt-1" />
                                        </div>
                                    ) : (
                                        user.image !== null  ? (
                                            <div className="imageProfile" style={{backgroundImage: `url(${user.imageUrl})`}}>
                                            </div>
                                        ) : (
                                            <div className="imageProfile">
                                            </div>
                                        )
                                    )}                                        
                                    <Button
                                        variant="contained"
                                        component="label"
                                    >
                                        {user.image === null ? 'Shto foto' : 'Ndrysho'}
                                        <input
                                            type="file"
                                            hidden
                                            onChange={uploadImage}
                                        />
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            {loading ? (
                                <div className='text-center mt-4'>
                                    <CircularProgress size="18px" />
                                </div>
                            ) : (
                                <form onSubmit={updateProfileHandler}> 
                                    <div className='mt-2'>
                                        <TextField
                                            name="weddingLocation"
                                            variant="outlined"
                                            fullWidth
                                            label="Emri"
                                            size="small"
                                            value={name}
                                            required
                                            onChange={(e) => { setName(e.target.value) }}
                                        />
                                    </div>
                                    <div className='mt-2'>
                                        <TextField
                                            required
                                            name="weddingLocation"
                                            variant="outlined"
                                            fullWidth
                                            label="Telefoni"
                                            size="small"
                                            value={phone}
                                            onChange={(e) => { setPhone(e.target.value) }}
                                        />
                                    </div>
                                    <div className='mt-2'>
                                        <TextField
                                            name="weddingLocation"
                                            variant="outlined"
                                            fullWidth
                                            label="Qyteti"
                                            size="small"
                                            value={city}
                                            onChange={(e) => { setCity(e.target.value) }}
                                        />
                                    </div>
                                    <div className='mt-2'>
                                        <TextField
                                            name="weddingLocation"
                                            variant="outlined"
                                            fullWidth
                                            label="Linku i instagram"
                                            size="small"
                                            value={instagram}
                                            onChange={(e) => { setInstagram(e.target.value) }}
                                        />
                                    </div>
                                    <div className='mt-2'>
                                        <TextField
                                            name="weddingLocation"
                                            variant="outlined"
                                            fullWidth
                                            label="Linku i facebook"
                                            size="small"
                                            value={facebook}
                                            onChange={(e) => { setFacebook(e.target.value) }}
                                        />
                                    </div>
                                    <div className="text-center mt-4">
                                        <Button
                                            variant="contained"
                                            type="submit"
                                        >
                                            Ruaj
                                        </Button>
                                    </div>
                                </form>
                            )}
                        </div>
                    </div>
                )}
            </CardContent>
        </Card>
    );
};

export default BusinessProfile;