import * as c from "../constants";
import API from '../api';

export const getClientBySlug = (slug) => async(dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const response = await API.get(`/getClientBySlug/${slug}`, config)
        dispatch({ type: c.GET_CLIENT, payload: response.data })
        return response
    } catch (error) {
        throw error
    }
}

export const getImagesBeforeWeeding = (slug) => async(dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const response = await API.get(`/getImagesBeforeWeeding/${slug}`, config)
        dispatch({ type: c.GET_CLIENT_BEFORE_WEDDING, payload: response.data })
        return response
    } catch (error) {
        throw error
    }
}

export const getImagesAfterWedding = (slug) => async(dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const response = await API.get(`/getImagesAfterWedding/${slug}`, config)
        dispatch({ type: c.GET_CLIENT_AFTER_WEDDING, payload: response.data })
        return response
    } catch (error) {
        throw error
    }
}