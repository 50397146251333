import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { TextField, Button, LinearProgress } from "@mui/material";
import { toggleDrawer, toggleLoading } from '../../../../redux/actions/app';
import { updateCategory } from '../../../../redux/actions/business';
import toast from 'react-hot-toast';

import styles from '../clients.module.scss';

const EditCategory = (props) => {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [name, setName] = useState(props.category.name);

    const newCategoryFormHandler = (e) => {
        e.preventDefault();
        setLoading(true)

        const category_data = {
            "name": name
        };

        dispatch(updateCategory(category_data, props.category.id)).then(res => {
            setLoading(false);
            dispatch(toggleDrawer(false));
            toast.success('Kategoria u ndryshua me sukses');
            props.onFinish();
        }).catch(e => {
            toast.error('Dicka shkoi keq, ju lutem provoni perseri!');
            setLoading(false)
        })
    }

    return (
        <form autoComplete="off" onSubmit={newCategoryFormHandler}>
            <div className='mt-2'>
                <TextField
                    name="fullname"
                    variant="outlined"
                    fullWidth
                    label="Kategoria"
                    size="small"
                    value={name}
                    onChange={(e) => { setName(e.target.value) }}
                    required
                />
            </div>
            <div className="text-center mt-4">
                <Button variant="contained" type="submit">
                    Ndrysho kategorinë
                </Button>
                {loading && <LinearProgress color="primary" className="mt-1" />}               
            </div>
        </form>
    );
};

export default EditCategory;