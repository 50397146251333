import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Box from '@mui/material/Box';
import { getClientBySlug, getImagesBeforeWeeding, getImagesAfterWedding } from '../../redux/actions/public';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import locationImg from "./location.png";
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import styles from "./client.module.scss";
import './footer.css';
import { CardContent, Card } from '@mui/material';

const Client = () => {
    const dispatch = useDispatch();
    const { slug } = useParams();
    const client = useSelector((state) => state.public.client);
    const clientImagesBeforeWedding = useSelector((state) => state.public.images_before_wedding);
    const clientImagesAfterWedding = useSelector((state) => state.public.images_after_wedding);

    const [loading, setLoading] = useState(true);
    const [value, setValue] = useState("all");
    const [selectedImage, setSelectedImage] = useState();
    const [filteredImages, setFilteredImages] = useState();

    const handleChange = (event, newValue) => {
        setValue(newValue);
        if(newValue !== "all" && newValue !== "video"){
            setFilteredImages(clientImagesAfterWedding.filter(img => img.category_id.toString() === newValue.toString()));
        }
    };

    useEffect(() => {
        setLoading(true);
        dispatch(getClientBySlug(slug)).then(res => {
            if(res.data.type === 'before_wedding'){
                dispatch(getImagesBeforeWeeding(slug)).then(res => {
                    setLoading(false);
                }).catch(e => {
                    setLoading(false);
                })
            }else{
                dispatch(getImagesAfterWedding(slug)).then(res => {
                    setLoading(false);
                }).catch(e => {
                    setLoading(false);
                })
            }
        }).catch(e => {
            setLoading(false);
        });
    }, []);

    return(
        <div className={styles.ClientWrapper}>
            {!loading && client.type === "before_wedding" && (
                <div>
                    <Carousel swipeable="true" autoPlay={true} interval={5000}>
                        {client && clientImagesBeforeWedding && clientImagesBeforeWedding.map((img, index) => (
                            <div key={index} className="d-flex align-items-center">
                                <img src={img.imageUrl} alt="" className="imgInCarousel" />
                            </div>
                        ))}                                
                    </Carousel>
                    {client.wedding_location && (
                        <a className={styles.locationButton} target="_blank" href={client.wedding_location}><img width="100px" src={locationImg} /></a>
                    )}
                    <hr></hr>
                    <div className='text-center footer'>
                        {client.business.image && (
                            <div className='d-flex justify-content-center'>
                                <div className="imageProfileOfBusiness" style={{backgroundImage: `url(${client.business.imageUrl})`}}>
                                </div>
                            </div>
                        )}
                        <div>
                            <p className='business-name'>{client.business.name}</p>
                        </div>
                        <div className='text-center'>
                            { client.business.instagram && ( <a className='social-button' target="_blank" href={client.business.instagram}><InstagramIcon color="inherit" /></a> )}
                            { client.business.facebook && (<a className='social-button' target="_blank" href={client.business.facebook}><FacebookIcon /></a> )}
                        </div>
                    </div>
                </div>
            )}
            {!loading && client.type === "after_wedding" && (
                <div style={{height: '100%'}}>
                    <Box
                        sx={{
                            flexGrow: 1,
                            bgcolor: 'background.paper',
                        }}
                    >
                        <Tabs
                            value={value ? value : null}
                            onChange={handleChange}
                            variant="scrollable"
                            scrollButtons
                            aria-label="visible arrows tabs example"
                        >
                            <Tab label="Të gjitha" value="all" />
                            {client.videos.length > 0 && <Tab label="Video" value="video" />}
                            {client && client.categories && client.categories.map(category => (
                                <Tab label={category.name} value={category.id} />
                            ))}
                        </Tabs>
                    </Box>
                    {value === "all" && (
                        <Card className='mt-2'>
                            <CardContent>
                                <div className="row mt-2" style={{alignItems: 'center'}}>
                                    {client && clientImagesAfterWedding && clientImagesAfterWedding.map((img, index) => (
                                        <div className='col-4 mt-2' key={index}>
                                            <img src={img.imageUrl} width="100%" onClick={() => setSelectedImage(img.imageUrl)} />
                                        </div>
                                    ))}
                                </div>

                                {selectedImage && (
                                    <div className={styles.ModalImg} onClick={() => setSelectedImage()}>
                                        <img className={styles.ModalContent} src={selectedImage} />
                                    </div>
                                )}
                            </CardContent>
                        </Card>
                    )}
                    {value === "video" && (
                        <div className='d-flex justify-content-center align-items-center'>
                            <video width="100%" controls>
                                <source src={client.videos[0].videoUrl} />
                            </video>
                        </div>
                    )}
                    {value !== "all" && value !== "video" && (
                        <Card className='mt-2'>
                            <CardContent>
                                <div className="row mt-2" style={{alignItems: 'center'}}>
                                    {client && filteredImages && filteredImages.map((img, index) => (
                                        <div className='col-4 mt-2' key={index}>
                                            <img src={img.imageUrl} width="100%" onClick={() => setSelectedImage(img.imageUrl)} />
                                        </div>
                                    ))}
                                </div>

                                {selectedImage && (
                                    <div className={styles.ModalImg} onClick={() => setSelectedImage()}>
                                        <img className={styles.ModalContent} src={selectedImage} />
                                    </div>
                                )}
                            </CardContent>
                        </Card>
                    )}
                </div>
            )}
        </div>
    );
};

export default Client;